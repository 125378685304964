<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <h4 class="m-1">
            Onboarding - <small>Before you can start searching for experts or seekers please complete the required steps</small>
          </h4>
        </div>
        <div class="float-right">
          Click the suitcase above to track your onboaring progress
          <feather-icon
            icon="CornerRightUpIcon"
            size="21"
            class="mr-5"
          />
        </div>
      </div>

      <!-- Todo List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="todo-task-list-wrapper list-group scroll-area"
      >
        <ul class="todo-task-list media-list">
          <li
            v-for="task in tasks"
            :key="task.id"
            class="todo-item"
            :class="{ 'completed': task.isCompleted }"
            @click="handleTaskClick(task)"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="draggable-task-handle d-inline"
            />
            <div class="todo-title-wrapper">
              <div class="todo-title-area">
                <div class="title-wrapper">
                  <span class="todo-title">{{ task.title }}</span> 
                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge
                    v-for="tag in task.tags"
                    :key="tag"
                    pill
                    :variant="`light-${resolveTagVariant(tag)}`"
                    class="text-capitalize"
                  >
                    {{ tag }}
                  </b-badge>
                </div>
                <!-- <small class="text-nowrap text-muted mr-1">{{ formatDate(task.dueDate, { month: 'short', day: 'numeric'}) }}</small> -->
                <b-form-checkbox
                  disabled
                  :checked="task.isCompleted"
                  @click.native.stop
                />
              </div>
            </div>
            <div class="ml-1">
              <p><small class="notification-text">{{ task.subtitle }}</small></p>
            </div>
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import {
  ref
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BBadge, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import TodoTaskHandlerSidebar from '../todo/TodoTaskHandlerSidebar.vue'
import TodoLeftSidebar from '../todo/TodoLeftSidebar.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BBadge,
    BAvatar,
    draggable,
    VuePerfectScrollbar,
    TodoTaskHandlerSidebar,
    TodoLeftSidebar
  },
  methods: {
    handleTaskClick(taskData){
      console.log(taskData)
      this.$router.push({name: taskData.route})
    },
  },
  setup() {

    const userData = JSON.parse(localStorage.getItem('userData'))
    const tasks = ref([])
    tasks.value = [
      {
        title: 'Complete Profile',
        subtitle: "Add your tagline, industry, and bio before searching others' profiles.",
        type: 'light-danger',
        icon: 'AlertTriangleIcon',
        status: 'Required',
        isCompleted: (userData.profileComplete)?true:false,
        route: 'apps-edit-profile'
      },
      {
        title: 'Update Availability',
        subtitle: 'Set your weekly availability so others can automatically book time with you.',
        type: 'light-danger',
        icon: 'AlertTriangleIcon',
        status: 'Required', 
        isCompleted: (userData.availabilityUpdated)?true:false,
        route: 'apps-edit-availability'
      },
      {
        title: 'Create a Product or Idea',
        subtitle: 'Looking to get feedback from customers or experts on your idea or product.',
        type: 'light-primary',
        icon: 'AlertTriangleIcon',
        status: 'Recommended',
        isCompleted: (userData.ideaPage)?true:false,
        route: 'pages-ideas-new',
      },
      {
        title: 'Enable Payments',
        subtitle: 'Save time and reduce no-shows by automatically collecting payments at the time of the booking.',
        type: 'light-primary',
        icon: 'AlertTriangleIcon',
        status: 'Recommended',
        isCompleted: (userData.stripeComplete)?true:false,
        route: 'integrations-stripe'
      },
      {
        title: 'Sync Google Calendar',
        subtitle: 'Make sure to attend all meetings! Automatically creates a meeting event when a user requests a booking.',
        type: 'light-primary',
        icon: 'AlertTriangleIcon',
        status: 'Recommended',
        isCompleted: (userData.googleToken)?true:false,
        route: 'integrations-google'
      },
      {
        title: 'Validate Account',
        subtitle: 'Give your account more credibility and visibility by connecting your LinkedIn profile.',
        type: 'light-primary',
        icon: 'AlertTriangleIcon',
        status: 'Recommended',
        isCompleted: (userData.linkedinID)?true:false,
        route: 'integrations-linkedin',
      },
      {
        title: 'Share Profile',
        subtitle: 'Want more visibility to your profile? Share your profile on your social networks!',
        type: 'light-primary',
        icon: 'AlertTriangleIcon',
        status: 'Recommended',
        isCompleted: (userData.sharedProfile)?true:false,
        route: 'apps-share'
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const taskTags = [
      { title: 'Team', color: 'primary', route: { name: 'apps-todo-tag', params: { tag: 'team' } } },
      { title: 'Low', color: 'success', route: { name: 'apps-todo-tag', params: { tag: 'low' } } },
      { title: 'Medium', color: 'warning', route: { name: 'apps-todo-tag', params: { tag: 'medium' } } },
      { title: 'High', color: 'danger', route: { name: 'apps-todo-tag', params: { tag: 'high' } } },
      { title: 'Update', color: 'info', route: { name: 'apps-todo-tag', params: { tag: 'update' } } },
    ]

    const resolveTagVariant = tag => {
      if (tag === 'team') return 'primary'
      if (tag === 'low') return 'success'
      if (tag === 'medium') return 'warning'
      if (tag === 'required') return 'danger'
      if (tag === 'recommended') return 'info'
      return 'primary'
    }

    const resolveAvatarVariant = tags => {
      if (tags.includes('high')) return 'primary'
      if (tags.includes('medium')) return 'warning'
      if (tags.includes('low')) return 'success'
      if (tags.includes('update')) return 'danger'
      if (tags.includes('team')) return 'info'
      return 'primary'
    }

    return {
      tasks,
      taskTags,
      perfectScrollbarSettings,

      resolveAvatarVariant,
      resolveTagVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.draggable-task-handle {
position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    cursor: move;

    .todo-task-list .todo-item:hover & {
      visibility: hidden;
    }
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
